<template>
  <div class="grid grid-cols-12 h-screen align-middle">
    <div class="col-span-6 bg-blue-200 text-center bg-login"></div>
    <div class="col-span-6 grid grid-cols-12 bg-blue-50 text-center items-center">
      <form @submit.prevent="login" class="col-span-6 col-start-4">
        <h1 class="text-center">Login</h1>
        <input type="text" class="bg-gray-100 border border-gray-300 p-2 m-2" placeholder="Login">
        <input type="text" class="bg-gray-100 border border-gray-300 p-2 m-2" placeholder="Senha"><br>
        <button class="bg-blue-500 text-white p-2 m-2">Entrar</button>
      </form>
    </div>
  </div>
</template>

<script>
import { useRouter } from 'vue-router';
export default {
  name: 'AppLogin',
  setup(){
    const router = useRouter();

    function login() {
      router.push({ name: 'MainHome' });
    }

    return {
      login
    }
  }
};
</script>

<style scoped></style>