<template>
  <button class="btn-secondary m-1" @click="isModalOpen = true">Gerar E-mail de Revisão</button>
  <overlay-modal :isOpen="isModalOpen" @close="isModalOpen = false">
    <form @submit.prevent="sendEmail">
      <input class="input w-full mb-4" placeholder="Destinatário" type="text" v-model="recipient">
      <input class="input w-full mb-4" placeholder="Título" type="text" v-model="title">
      <textarea class="input w-full mb-4" placeholder="E-mail" cols="55" rows="10" v-model="content"></textarea>
      <button class="btn">Enviar</button>
    </form>
  </overlay-modal>
</template>

<script>
// eslint-disable-next-line
import axios from 'axios';
import { ref } from 'vue';
import OverlayModal from "./shared/OverlayModal.vue";

export default {
  components: { OverlayModal },
  name: 'ReviewEmail',
  setup(){
    const query = ref('');
    const result = ref('');
    const recipient = ref('');
    const title = ref('Revisão de Contrato');
    const content = ref('');
    const isModalOpen = ref(false);

    function sendEmail() {
      console.log('Enviado');
    }

    return {
      result,
      query,
      recipient,
      title,
      content,
      isModalOpen,
      sendEmail
    }
  }
}
</script>

<style scoped>
.status-bar{
  min-width: 3px !important;
  max-width: 5px !important;
	padding: 0 !important;
}
.status-1{
  background-color: blue;
}
</style>