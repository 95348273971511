<template>
	<div class="grid grid-cols-12 gap-0 h-screen w-screen text-gray-600">
		<div class="col-span-1 text-center p-4 flex flex-col h-full">
			<img src="/img/logo.png" alt="" class="mx-auto mb-10">
			<div class="mt-auto">
				<a href="/login" class="p-2 flex items-center">
					<i class="fas fa-sign-out-alt w-8 text-center"></i>
					<span class="ml-2">Sair</span>
				</a>
			</div>
		</div>

		<div class="col-span-10 p-4">
			<div class="grid grid-cols-12">
				<div class="col-span-8 grid grid-cols-12">
					<input type="text" class="col-span-9 input">
					<button class="col-span-3 btn-secondary-small ml-2">Buscar</button>
				</div>
				<div class="col-span-2 col-start-11 flex items-center justify-end">
					<span>Nome do Usuário</span> <i style="margin-left:10px"
						class="fas fa-user-circle text-6xl"></i>
				</div>
			</div>
			<div class="grid grid-cols-12 gap-5">
				<h2 class="col-span-6 font-bold text-4xl my-5">Dashboard</h2>
				<div class="col-span-6 flex align-middle justify-end relative">
					<button id="novoDocBtn" @click="showDropdown = true" class="btn my-3">
						<i class="fas fa-plus"></i> Novo
					</button>
					<!-- Dropdown Menu -->
					<div v-if="showDropdown" id="dropdownMenu"
						class="absolute mt-12 right-0 bg-white border border-gray-300 rounded shadow-lg z-10">
						<div class="text-right">
							<button
								@click="showDropdown = false"
								class="p-2">&times;</button>
						</div>
						<form @submit.prevent="sendFile" class="flex items-center p-10">
							<input type="file" @change="onFileChange">
							<button class="btn flex items-center justify-center" :disabled="loading">
								<span v-if="!loading">Enviar</span>
								<span v-else class="loader border-t-primary border-t-2"></span> <!-- Spinner animation using Tailwind -->
							</button>
						</form>
					</div>
				</div>
				<div class="col-span-4 bg-blue-100 rounded p-5">
					Processados:
					<p class="font-extrabold text-8xl text-center">{{ processed }}</p>
				</div>
				<div class="col-span-4 bg-yellow-100 rounded p-5">
					Processando:
					<p class="font-extrabold text-8xl text-center">{{ processing }}</p>
				</div>
				<div class="col-span-4 bg-green-100 rounded p-5">
					Aprovados:
					<p class="font-extrabold text-8xl text-center">{{ approved }}</p>
				</div>
				<div class="col-span-12">
					<div class="flex text-left flex-grow">
						<a href="#" class="p-2 flex items-center">
							<i class="fas fa-file w-8 text-center"></i>
							<span class="ml-2">Documentos</span>
						</a>
						<a href="#" class="p-2 flex items-center">
							<i class="fas fa-list-alt w-8 text-center"></i>
							<span class="ml-2">Todos</span>
						</a>
						<a href="#" class="p-2 flex items-center">
							<i class="fas fa-clock w-8 text-center"></i>
							<span class="ml-2">Em Processo</span>
						</a>
						<a href="#" class="p-2 flex items-center">
							<i class="fas fa-check-circle w-8 text-center"></i>
							<span class="ml-2">Finalizados</span>
						</a>
						<a href="#" class="p-2 flex items-center">
							<i class="fas fa-times-circle w-8 text-center"></i>
							<span class="ml-2">Cancelados</span>
						</a>
						<a href="#" class="p-2 flex items-center">
							<i class="fas fa-file-download w-8 text-center"></i>
							<span class="ml-2">Baixados</span>
						</a>
						<a href="#" class="p-2 flex items-center">
							<i class="fas fa-trash w-8 text-center"></i>
							<span class="ml-2">Lixeira</span>
						</a>
					</div>
					<document-list></document-list>
				</div>
			</div>
		</div>
	</div>
    <toast-notification :message="toastMessage" @close="toastMessage = null" />
</template>

<script>
import DocumentList from "../components/DocumentList.vue";
import ToastNotification from '../components/ToastNotification.vue';
import axios from 'axios';
import { useRouter } from 'vue-router';
import { ref } from 'vue';
import { v4 as uuidv4 } from 'uuid';
export default {
	name: 'MainHome',
	components: {
		ToastNotification,
		DocumentList
	},
	setup() {
		let processed = ref(26);
		let processing = ref(27);
		let approved = ref(28);
		let showDropdown = ref(false);
		let selectedFile = null;
		let showToast = ref(false);
		let toastMessage = ref(null);
		let loading = ref(false);
		let sessionId = ref('');
		let userId = ref('');
		const router = useRouter(); // Initialize the router

		function onFileChange(event) {
			if (event.target.files[0].type == 'application/pdf') {
				selectedFile = event.target.files[0];
				sessionId.value = uuidv4();
				userId.value = 200
			} else {
				event.target.value = ''; // Clear the file input
				triggerToast('Formato Inválido');
				return false;
			}
		}

		function sendFile() {
			if (!selectedFile) {
				alert('Please select a file');
				return;
			}

			loading.value = true; // Start loading

			const formData = new FormData();
			formData.append('files', selectedFile);

			axios.post(
					process.env.VUE_APP_API_URL + `/app/local_index?user_id=${userId.value}&session_id=${sessionId.value}`,
					formData,
					{
						headers: {
						'Content-Type': 'multipart/form-data',
						},
					}
				)
				.then(response => {
					console.log('File uploaded successfully', response.data);
					router.push({ name: 'DocumentData', query: { userId: userId.value, sessionId: sessionId.value } });

				})
				.catch(error => {
					console.error('Error uploading file', error);
				});
		}

		const triggerToast = (message) => {
			console.log('Formato invalido');
			toastMessage.value = message;
			showToast.value = true;
		};

		return {
			processed,
			processing,
			approved,
			showDropdown,
			loading,

			sendFile,
			onFileChange,
			showToast,
			toastMessage,
		}
	}
};
</script>

<style scoped>
.loader {
  @apply w-5 h-5 border-4 border-solid rounded-full animate-spin;
}
</style>