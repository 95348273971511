<template>
  Página não encontrada
</template>

<script>
export default {
  name: 'NotFound',
};
</script>

<style scoped></style>