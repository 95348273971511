<template>
	<div class="text-left">
    <div class="flex flex-col items-center content-center">
      <donut-graph :percentage="percentage" :size-prop="150" />
      <!-- Slider to set threshold -->
      <label for="threshold">Threshold: {{ threshold }}%</label>
      <input type="range" id="threshold" v-model="threshold" min="0" max="100" step="1">

      <p class="text-center mb-3">O contrato está {{ percentage }}% aderente</p>

      <!-- Check if the contract passes -->
      <p v-if="percentage >= threshold"
        class="text-green-500 font-bold">O contrato cumpre os requisitos!</p>
      <p v-else
        class="text-red-500 font-bold">O contrato não cumpre os requisitos</p>
    </div>
    <div
      v-for="(validation, index) in validations"
      :key="index"
      class="flex justify-between p-5 border-1 border-gray-500">
      <details class="cursor-pointer link-color">
        <summary class="font-bold">
          {{ validation.short_question }}
          <i class="fa fa-copy text-blue-400" @click="copyQuestion(validation.long_question)"></i>
        </summary>
        <div style="max-height: 12vh; overflow: scroll">
          <p
            v-for="(question, index) in validation.long_question" :key="index">
            {{question}}
          </p>
          <p
            v-html="validation.answer">
          </p>
        </div>
      </details>
      <i v-if="validation.answer && validation.passes" class="fa fa-check-circle text-green-400"></i>
      <i v-else-if="validation.answer" class="fa fa-times-circle text-red-400"></i>
      <dot-loader v-else></dot-loader>
    </div>
	</div>
</template>

<script>
import axios from 'axios';
import { onMounted, ref, watch } from 'vue';
import DotLoader from './shared/DotLoader.vue';
import DonutGraph from './shared/DonutGraph.vue';

export default {
  components: { DotLoader, DonutGraph },
  props: ['userId', 'sessionId'],
  name: 'DocumentValidation',
  setup(props, {emit}){
    let messages = ref([]);
    let input = ref('');
    let loading = ref(false);
    let validations = ref([
      {
        'short_question': 'CNPJ da contratada está na base de fornecedores?',
        'long_question': ['1. Encontre o CNPJ da parte contratada',
                          '2. Com o CNPJ encontrado, procure por ele na lista de fornecedores',
                          '3. Com essas informações, responda: o CNPJ da parte contratada está incluso na lista de fornecedores?'],
        'answer': null,
        'passes': false
      },
      {
        'short_question': 'Valor do contrato precisa de aprovação do diretor?',
        'long_question': ['1. Qual o valor total do contrato?',
                          '2. Qual valor necessita de aprovação do diretor da área de acordo com o art 16 do Estatuto Social?',
                          '3. Compare os dois valores e responda: esse contrato precisa de aprovação do diretor?'],
        'answer': null,
        'passes': false
      },
      {
        'short_question': 'Forma de pagamento é aceita pela política da empresa?',
        'long_question': ['1. Qual a forma de pagamento especificada no contrato?',
                          '2. Quais formas de pagamento são aceitas pela política de compliance?',
                          '3. Compare as duas informações e responda: a forma de pagamento especificada no contrato é aceita pela política de compliance?'],
        'answer': null,
        'passes': false
      },
      {
        'short_question': 'Cláusula de multa para pagamento fora do prazo de vencimento está de acordo com a política da empresa?',
        'long_question': ['1. O que diz a cláusula de multa para pagamento fora do prazo de vencimento no contrato?',
                          '2. O que a política de compliance estabelece referente a multa para pagamento fora do prazo?',
                          '3. Compare as duas informações e responda: a multa do contrato está de acordo com o que a política de compliance estabelece sobre multas? 4. Caso não esteja de acordo recomende o texto a ser incluido/ajustado no contrato sem citar a política de compliance'],
        'answer': null,
        'passes': false
      },
      {
        'short_question': 'Termos de LGPD estão de acordo com a política de empresa?',
        'long_question': ['1. Quais os termos relativos à LGPD no contrato?',
                          '2. Encontre os procedimentos relativos à LGPD especificados na política de compliance',
                          '3. Compare as duas informações e responda: os termos do contrato sobre a LGPD estão de acordo com os termos da política de compliance sobre LGPD?'],
        'answer': null,
        'passes': false
      },
    ]);
    let percentage = ref(0);
    let threshold = ref(100);

    // Watcher to observe changes in passes
    watch(validations, (newVal) => {
      const totalValidations = newVal.length;
      const passedValidations = newVal.filter(item => item.passes).length;
      percentage.value = (passedValidations / totalValidations) * 100;
    }, { deep: true });

    function fetch(input, index) {
      loading.value = true;
      axios.post(process.env.VUE_APP_API_URL + 'agent/invoke', {
        "input": {
          "question": input
        },
        "config": {
          "configurable": {
            "session_id": props.sessionId,
            "user_id": props.userId
            }
          }
      }, {
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'text'
      })
        .then(response => {
          validations.value[index].answer = JSON.parse(response.data).output.output;
          if (validations.value[index].answer.startsWith('Sim')) {
            validations.value[index].passes = true
          }
        }).catch(error => {
          console.error(error);
        }).finally(() => {
          loading.value = false;
        });
    }

    function copyQuestion(question) {
      console.log(question);
      emit('copied', question);
      
    }

    onMounted(() => {
      validations.value.forEach((validation, index) => {
        fetch('Insira sim ou não no inicio da resposta se a resposta for positiva ou negativa respectivamente e responda cada um dos tópicos além de dar uma sugestão de como corrir os que tiverem inválidos: ' + validation.long_question, index);
      });
    })

    return {
      messages,
      input,
      loading,
      validations,
      percentage,
      threshold,
      copyQuestion
    }
  }
};
</script>

<style scoped>
.link-color a{
  color: blue;
}
</style>