<template>
  <transition name="slide-up">
    <div v-if="message" class="toaster">
      <span class="toaster-message">{{ message }}</span>
      <button class="toaster-close" @click="hideToast" aria-label="Close">&times;</button>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const hideToast = () => {
      emit('close');
    };

    return {
      hideToast,
    };
  },
};
</script>

<style scoped>
.toaster {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #333;
  color: white;
  padding: 15px 30px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toaster-message {
  margin-right: 10px;
}

.toaster-close {
  background: none;
  border: none;
  color: white;
  font-size: 18px;
  padding: 5px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.toaster-close:hover {
  color: #f00;
}

/* Transition classes for sliding up */
.slide-up-enter-active, .slide-up-leave-active {
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.slide-up-enter-from {
  transform: translateY(100%);
  opacity: 0;
}

.slide-up-enter-to {
  transform: translateY(0);
  opacity: 1;
}

.slide-up-leave-from {
  transform: translateY(0);
  opacity: 1;
}

.slide-up-leave-to {
  transform: translateY(100%);
  opacity: 0;
}
</style>
