<template>
  <div class="message-content flex space-x-1">
    <div class="dot bg-gray-800"></div>
    <div class="dot bg-gray-800"></div>
    <div class="dot bg-gray-800"></div>
  </div>
</template>

<script>
export default {
  name: 'DotLoader'
}
</script>

<style scoped>
@keyframes dot-pulse {
  0%, 20% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: dot-pulse 1.5s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}
</style>