<template>
  <div v-if="isOpen" class="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center z-40" @click="closeModal">
    <!-- Modal Content -->
    <div class="bg-white p-1 rounded-lg shadow-lg" @click.stop>
      <div class="flex justify-end">
        <button
          class="px-2 bg-gray-500 text-white rounded hover:bg-gray-600 focus:outline-none"
          @click="closeModal"
        >
          &times;
        </button>
      </div>
      <!-- Slot for passing custom content -->
      <div class="p-5">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true
    }
  },
  methods: {
    closeModal() {
      this.$emit('close');
    }
  }
};
</script>
