<template>
  <div class="flex justify-center items-center">
    <svg
      viewBox="0 0 36 36"
      class="donut"
      :width="size"
      :height="size"
    >
      <!-- Red Background Circle -->
      <circle
        class="donut-background"
        cx="18"
        cy="18"
        r="13.9155"
        fill="transparent"
        stroke="#ff4d4d"
        stroke-width="7"
      />
      <!-- Green Progress Circle -->
      <circle
        class="donut-progress"
        cx="18"
        cy="18"
        r="13.9155"
        fill="transparent"
        stroke="#4caf50"
        stroke-width="7"
        stroke-dasharray="100, 100"
        stroke-dashoffset="25"
        :style="{ strokeDasharray: `${validPercentage}, 100` }"
      />
    </svg>
  </div>
</template>

<script>
import { computed } from 'vue'

export default {
  props: {
    percentage: {
      type: Number,
      required: true,
      validator(value) {
        return value >= 0 && value <= 100
      },
    },
    sizeProp: {
      type: Number,
      default: 100,
    },
  },
  setup(props) {
    // Computed property to update the percentage
    const validPercentage = computed(() => Math.min(Math.max(props.percentage, 0), 100))

    return {
      validPercentage,
      size: props.sizeProp,
    }
  },
}
</script>

<style scoped>
.donut {
  transform: rotate(-90deg); /* Rotate to start progress at the top */
}

.donut-progress {
  stroke-linecap: round;
  transition: stroke-dasharray 0.35s;
  transform: rotate(0.25turn);
  transform-origin: 50% 50%;
}
</style>
