import { createRouter, createWebHistory } from 'vue-router';
import MainHome from '../views/MainHome.vue';
import AppLogin from '../views/AppLogin.vue';
import DocumentData from '../views/DocumentData.vue';
import NotFound from '../views/NotFound.vue';

const routes = [
  {
    path: '/',
    name: 'MainHome',
    component: MainHome
  },
  {
    path: '/login',
    name: 'AppLogin',
    component: AppLogin
  },
  {
    path: '/about-document',
    name: 'DocumentData',
    component: DocumentData,
    props: true
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
