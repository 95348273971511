<template>
  <table class="w-full table">
    <thead>
      <tr>
        <th></th>
        <th class="text-left">Documento</th>
        <th class="text-left">Responsável</th>
        <th class="text-left">Última Alteração</th>
      </tr>
    </thead>
    <tbody>
      <tr>
        <td class="status-bar bg-blue-300">
        </td>
        <td>Novo.pdf</td>
        <td>Jonh</td>
        <td>20/10/2023</td>
      </tr>
      <tr>
        <td class="status-bar bg-yellow-300">
        </td>
        <td>Novo.pdf</td>
        <td>Jonh</td>
        <td>20/10/2023</td>
      </tr>
      <tr>
        <td class="status-bar bg-green-300">
        </td>
        <td>Novo.pdf</td>
        <td>Jonh</td>
        <td>20/10/2023</td>
      </tr>    </tbody>
  </table>
</template>

<script>
// eslint-disable-next-line
import axios from 'axios';
import { ref } from 'vue';

export default {
  name: 'SearchBar',
  setup(){
    let query = ref('');
    let result = ref('');

    /* function search() {
      axios.get('https://openlibrary.org/search.json?q='+query.value)
        .then(response => {
          console.log(response.data);
        }).catch(error => {
          console.error(error);
        });
    } */

    return {
      result,
      query
    }
  }
}
</script>

<style scoped>
.status-bar{
  min-width: 3px !important;
  max-width: 5px !important;
	padding: 0 !important;
}
.status-1{
  background-color: blue;
}
</style>