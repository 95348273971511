<template>
	<div
    v-if="userId && sessionId"
    class="grid grid-cols-12 gap-0 h-screen w-screen text-gray-600">
    <div class="col-span-10 col-start-2 grid grid-cols-12">
			<a href="/" class="col-span-3 my-auto">
        <img src="/img/logo.png" alt="">
      </a>
      <div class="col-span-7 grid grid-cols-12 items-center">
        <input type="text" class="col-span-9 input">
        <button class="col-span-3 btn-secondary-small ml-2">Buscar</button>
      </div>
      <div class="col-span-2 col-start-11 flex items-center justify-end">
        <span>Nome do Usuário</span> <i style="margin-left:10px"
          class="fas fa-user-circle text-6xl"></i>
      </div>
    </div>
    

    <div class="flex h-full w-full col-span-12">
      <!-- Left Div -->
      <div
        class="p-4 flex flex-col h-full"
        :style="{ width: leftWidth + '%' }"
      >
        <document-validation
          :user-id="userId"
          :session-id="sessionId"
          @copied="inputQuestion"
        ></document-validation>
        <div class="mt-4">
          <button class="btn m-1">Gerar Contrato Revisado</button>
          <button class="btn-secondary m-1">Gerar Contrato Comentado</button>
          <review-email></review-email>
        </div>
      </div>

      <!-- Resizer -->
      <div
        class="w-2 bg-gray-300 cursor-ew-resize"
        @mousedown="startDragging"
      ></div>

      <!-- Right Div -->
      <div class="p-4 flex flex-col h-full" :style="{ width: rightWidth + '%' }">
        <div class="chat-container">
          <div class="chat-messages overflow-y-auto">
            <div
              v-for="(message, index) in messages"
              :key="index"
              :class="{
                'message-sent': message.type === 'sent',
                'message-received': message.type === 'received',
                'p-2 my-2': true
              }"
            >
              <div class="message-content">
                <div v-html="message.content"></div>
              </div>
            </div>
            <!-- Loader for "message-received" -->
            <div class="message-received p-2 my-2">
              <dot-loader v-if="loading"></dot-loader>
            </div>
          </div>

          <!-- Message Form -->
          <form @submit.prevent="sendMessage" class="grid grid-cols-9 gap-2 mt-4">
            <textarea
              class="col-span-7 input p-2 border border-gray-300 rounded"
              v-model="input"
              @keydown="checkEnter"
              rows="1"
              required
            ></textarea>
            <button class="col-span-1 btn text-center p-2 bg-blue-500 text-white rounded"><i class="fas fa-paper-plane"></i></button>
            <button type="button" @click="clearChat" class="col-span-1 btn-secondary text-center p-2 bg-gray-500 text-white rounded"><i class="fas fa-trash"></i></button>
          </form>
        </div>
      </div>
    </div>

  </div>
  <div v-else class="text-center mt-11">
    Não encontramos um arquivo, volte para a página inicial e anexe novamente
  </div>
</template>

<script>
import axios from 'axios';
import { ref, onMounted, onUnmounted } from 'vue';
import { useRoute } from 'vue-router'
import DotLoader from '../components/shared/DotLoader.vue';
import DocumentValidation from '../components/DocumentValidation.vue';
import ReviewEmail from '../components/ReviewEmail.vue';

export default {
  name: 'DocumentData',
  components: { DotLoader, DocumentValidation, ReviewEmail },
  setup(){
    let messages = ref([
      {
        'content': 'Envie suas dúvidas aqui',
        'type': 'received'
      }
    ]);
    let input = ref('');
    let loading = ref(false);
    const route = useRoute();
    const sessionId = route.query.sessionId;
    const userId = route.query.userId;

    const leftWidth = ref(50)  // initial width for the left div
    const rightWidth = ref(50) // initial width for the right div
    const isDragging = ref(false)
    const startX = ref(0)
    const initialLeftWidth = ref(0)

    const startDragging = (event) => {
      console.log('arrastando');
      
      isDragging.value = true
      startX.value = event.clientX
      initialLeftWidth.value = leftWidth.value
      document.addEventListener('mousemove', onDragging)
      document.addEventListener('mouseup', stopDragging)
    }

    const onDragging = (event) => {
      if (isDragging.value) {
        const deltaX = event.clientX - startX.value
        const newLeftWidth = initialLeftWidth.value + (deltaX / window.innerWidth) * 100

        // Ensure the widths stay within valid percentage range
        if (newLeftWidth >= 10 && newLeftWidth <= 90) {
          leftWidth.value = newLeftWidth
          rightWidth.value = 100 - leftWidth.value // Automatically adjust the right div's width
        }
      }
    }

    const stopDragging = () => {
      isDragging.value = false
      document.removeEventListener('mousemove', onDragging)
      document.removeEventListener('mouseup', stopDragging)
    }

    function fetch(input) {
      loading.value = true;
      axios.post(process.env.VUE_APP_API_URL + 'agent/invoke', {
        "input": {
          "question": input
        },
        "config": {
          "configurable": {
            "session_id": sessionId,
            "user_id": userId
            }
          }
      }, {
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'text'
      })
        .then(response => {
          let output = JSON.parse(response.data).output;
          receivedMessage(output.output);
        }).catch(error => {
          console.error(error);
          receivedMessage('erro');
        }).finally(() => {
          loading.value = false;
        });
    }

    function sendMessage() {
      let newMessage = {
        type: 'sent',
        content: input.value
      }
      messages.value.push(newMessage);
      fetch(input.value);
      input.value = '';
    }

    function clearChat() {
      messages.value = [
        {
          'content': 'Envie suas dúvidas aqui',
          'type': 'received'
        }
      ];
    }

    function receivedMessage(content) {
      let newMessage = {
        type: 'received',
        content: content
      }
      messages.value.push(newMessage);
    }

    function inputQuestion(question) {
      input.value = question;
    }

    onMounted(() => {
      document.addEventListener('mouseup', stopDragging)
    })

    onUnmounted(() => {
      document.removeEventListener('mouseup', stopDragging)
    })

    return {
      messages,
      input,
      sendMessage,
      loading,
      sessionId,
      userId,
      clearChat,
      startDragging,
      leftWidth,
      rightWidth,
      isDragging,
      startX,
      initialLeftWidth,
      inputQuestion
    }
  }
};
</script>

<style scoped>
.resizer {
  width: 2px;
  background-color: #e5e7eb; /* Tailwind's gray-300 */
  cursor: ew-resize;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: 100%;
  margin: 0 auto;
  border: 1px solid #e5e7eb; /* Tailwind gray-200 */
}

.chat-messages {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background-color: #f9fafb; /* Tailwind gray-50 */
}

.message-sent,
.message-received {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.message-sent .message-content {
  background-color: #4f46e5; /* Tailwind indigo-600 */
  color: white;
  border-radius: 10px;
  padding: 8px 12px;
  max-width: 70%;
  text-align: right;
  margin-left: auto;
}

.message-received .message-content {
  background-color: #e5e7eb; /* Tailwind gray-200 */
  color: #1f2937; /* Tailwind gray-800 */
  border-radius: 10px;
  padding: 8px 12px;
  max-width: 70%;
  text-align: left;
}

.message-content {
  word-break: break-word;
}

@keyframes dot-pulse {
  0%, 20% {
    opacity: 1;
  }
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  animation: dot-pulse 1.5s infinite ease-in-out;
}

.dot:nth-child(1) {
  animation-delay: 0s;
}

.dot:nth-child(2) {
  animation-delay: 0.3s;
}

.dot:nth-child(3) {
  animation-delay: 0.6s;
}
</style>